
// import ar from 'quasar/lang/ar';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-branch-list-page',
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',

      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: 'branch/view/rows',
      loading: 'branch/view/loading',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'branch/view/doFetch',
    }),
  },

  created() {
    this.doFetch();
  },

  mounted() {},
};
